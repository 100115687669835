<template>
  <div class="intro row">
    <div class="col-12 col-xl-4">
      <div class="bg-white h-100 p-4">
        <div class="mb-5">
          <p class="intro-title text-xl"><img src="@/assets/image/ai/quote.png" alt="">服務特色</p>
          <p class="intro-content text-lg">AI 小幫手整合最先進 AI 語言處理技術，透過智能化演算法，協助您快速生成「商用英文書信」、「英文演講稿」，並提供 AI「口說練習」功能，無論是臨時需要寫一封英文 E-mail 給客戶、準備下一場重要的英文演說，或欲利用零碎時間自我提升英語口說會話實力，AI 小幫手都是您可搭配使用的輔助工具。</p>
        </div>
        <div>
          <p class="intro-title text-xl"><img src="@/assets/image/ai/quote.png" alt="">點數說明</p>
          <p class="intro-content text-lg mb-2">本區採點數制，依企業方案提供初始點數供學員體驗 AI 服務，如點數使用完畢歡迎加購，以繼續使用 AI 小幫手各項服務。</p>
          <!-- <p class="intro-content text-lg">本區透過生成式 AI 即時互動反饋，若 AI 回應不符合您的預期，請嘗試重新生成，以參考您需要的內容。</p> -->
        </div>
      </div>
    </div>
    <div class="illustrate col-12 col-xl-8 text-lg m4t-3 mt-xl-0">
      <div class="intro-banner text-white py-4 text-xl mb-4">
        <p>使用說明</p>
      </div>
      <div class="d-flex flex-wrap">
        <div class="step step1 py-3 d-flex align-items-center justify-content-center">
          <p>選擇類型</p>
        </div>
        <div class="intro-text bg-white py-3 flex-1 px-3">
          <p>請在左側選擇您需要生成的內容</p>
        </div>
      </div>
      <div class="arrow my-2 mx-auto mx-sm-0">
        <img src="@/assets/image/ai/Arrow1.png" alt="" class="mx-auto d-block">
      </div>
      <div class="d-flex flex-wrap">
        <div class="step step2 py-3 d-flex align-items-center justify-content-center">
          <p>輸入資料</p>
        </div>
        <div class="intro-text bg-white py-3 flex-1 px-3">
          <p>在指定欄位中以鍵盤輸入或直接說出相關資訊，點擊送出後，請稍作等待，由 AI 小幫手為您完成目標任務。</p>
        </div>
      </div>
      <div class="arrow my-2 mx-auto mx-sm-0">
        <img src="@/assets/image/ai/Arrow2.png" alt="" class="mx-auto d-block">
      </div>
      <div class="d-flex flex-wrap">
        <div class="step step3 py-3 d-flex align-items-center justify-content-center">
          <p>查看成果</p>
        </div>
        <div class="intro-text bg-white py-3 flex-1 px-3">
          <p>AI 小幫手撰寫完畢後，請再次閱讀所生成內容是否符合所需，亦可依個人需求進行修改與調整。</p>
        </div>
      </div>
      <div class="arrow my-2 mx-auto mx-sm-0">
        <img src="@/assets/image/ai/Arrow3.png" alt="" class="mx-auto d-block">
      </div>
      <div class="d-flex flex-wrap">
        <div class="step step4 py-3 d-flex align-items-center justify-content-center">
          <p>更多功能</p>
        </div>
        <div class="intro-text bg-white py-3 flex-1 px-3">
          <p>歡迎點擊字典查詢、中文翻譯、全文播放、AI 代答、更多說明等延伸功能進行進一步學習。若想獲得更多靈感，請嘗試重新生成，或選擇不同情境，進行更多互動練習。</p>
        </div>
      </div>
      <!-- <div class="arrow my-2">
        <img src="@/assets/image/ai/Arrow4.png" alt="" class="mx-auto d-block">
      </div> -->
      <!-- <div class="d-flex">
        <div class="step step5 py-3">
          <p>更多功能</p>
        </div>
        <div class="intro-text bg-white py-3 flex-1 px-3">
          <p>想要得獲得更多靈感，請嘗試重新生成；更提供字典查詢、全文播放功能進行自我練習。</p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
  export default {
    name: 'intro'
  }
</script>
<style lang="scss" scoped>
  .intro {
    @media (min-width: 640px) {
      padding: 1.5rem !important;
    }
    @media (min-width: 640px) {
      padding: 1.5rem !important;
    }
  }
  .intro-title {
    line-height: 2;
    color: #F30000;
    font-weight: 900;
    @media (max-width: 1024px) {
      font-size: 1.125rem;
    }
  }
  .intro-content {
    line-height: 1.8;
    color: #1A1A1A;
    font-weight: 600;
    @media (max-width: 1024px) {
      font-size: 1.125rem;
    }
  }
  .intro-banner {
    background: #0E103E;
    text-align: center;
    // margin-bottom: 2rem;
    font-weight: 700;
    background-image: url('~@/assets/image/ai/plusB.png'), url('~@/assets/image/ai/plusS.png'), url('~@/assets/image/ai/plusB.png'), url('~@/assets/image/ai/plusS.png');
    background-repeat: no-repeat;
    background-position: left 10% bottom 20%, left 5% top 20%, right 10% bottom 20%, right 5% top 20%;
    p {
      margin: 0;
    }
    @media (max-width: 1024px) {
      font-size: 1.125rem;
    }
  }
  .illustrate {
    font-weight: 600;
    .arrow {
      width: 20%;
    }
    .step {
      width: 20%;
      @media (max-width: 575px) {
        width: 100%;
      }
    }
    .step1 {
      background-color: #252F82;
      p {
        text-align: center;
        color: white;
      }
    }
    .step2 {
      background-color: #642582;
      p {
        text-align: center;
        color: white;
      }
    }
    .step3 {
      background-color: #9B128D;
      p {
        text-align: center;
        color: white;
      }
    }
    .step4 {
      background-color: #D33F2A;
      p {
        text-align: center;
        color: white;
      }
    }
    .step5 {
      background-color: #F96F34;
      p {
        text-align: center;
        color: white;
      }
    }
    .intro-text{
      text-align: center;
      border-top: 1px solid #0E103E;
      border-bottom: 1px solid #0E103E;
      p {
        text-align: left;
        margin: 0;
        @media (max-width: 1024px) {
          font-size: 1.125rem;
        }
      }
    }
  }

</style>
